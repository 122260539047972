import { render, staticRenderFns } from "./tibi.vue?vue&type=template&id=0a616fcc&scoped=true&"
import script from "./tibi.vue?vue&type=script&lang=js&"
export * from "./tibi.vue?vue&type=script&lang=js&"
import style0 from "./tibi.vue?vue&type=style&index=0&id=0a616fcc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a616fcc",
  null
  
)

export default component.exports