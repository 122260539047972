<template>
  <div class="iso">
    <div class="i_main" :style="`background: url(${ColorCheckData=='black'? bglist.image: bglist.image1}) no-repeat;`">
      <div class="m_title">{{$t('lang.h47')}}</div>
      <div class="m_text">{{$t('lang.h48')}}</div>
      <div class="buttons">
        <div class="b_b1">{{ $t('lang.swap356') }}</div>
        <div class="b_b1">{{ $t('lang.swap357') }}</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="( item, index) of switchlist" :key="index" @click="shiswitch(index)"
            :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(182, 133, 255, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>

      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <div class="over" v-show="remenshow"></div>
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" 
            :placeholder="`${$t('lang.swap364')}`" class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <div class="meiyou">
        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
            <span>{{ $t('lang.swap365') }}</span>
        </div>
      <!-- <template >
        <div class="lon_list" v-for="item in 4" style="margin-bottom: 26px;">
          <div class="lon_title">
            <div class="title_img">
              <img src="@/assets/img/logo/test_logo.png" alt="">
              <img src="@/assets/img/logo/USDT.png" alt="" style="margin-left: -10px;">
            </div>
            <span>Water-Bull</span>
          </div>
          <div class="lon_line" style="margin-bottom: 22px;">
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">已赚取</span>
              <span style="color: rgba(0, 0, 0, 1);">0</span>
            </div>
            <div class="line_right_button">收割</div>
          </div>
          <div class="lon_line2" style="margin-bottom: 18px;">
            <div class="line2_title">质押</div>
            <div class="line2_button">授权</div>
          </div>
          <div class="lon_line3" >
            <div class="line3_left">累计激励</div>
            <div class="line3_right">100000 Water</div>
          </div>
          <div class="lon_line3">
            <div class="line3_left">累计激励</div>
            <div class="line3_right">100000 Water</div>
          </div>
        </div>
      </template> -->

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'iso',
  components: {

  },
  data() {
    return {
      regionIndex: 0,
      toInput:'',
      checked: false,
      remenshow: false,
      bglist:{
        image:require('@/assets/img/earnmoneybg.png'),
        image1:require('@/assets/img/earnmoneybg1.png'),
      },
      switchlist: ["lang.swap358", "lang.swap359"]
    }
  },
  computed: {
    ...mapState(['account', 'lang','ColorCheckData']),
  },
  created() {

  },
  methods: {
    shiswitch(index) {
      this.regionIndex = index;
    },
    remeshowfun() {
      this.remenshow = !this.remenshow
    }
  },
}
</script>

<style lang="less" scoped>
.iso {
  
  background: var(--bg-color);
}

.i_main {
text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size:   contain !important;
  background-position: 0 130px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var( --text-color);
    text-align: center;
    margin-top: 40px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158,156,163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "HarmonyOSSansSC";


  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {

      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
box-shadow: 0px 4px 8px  rgba(255, 255, 255, 0.12);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
      color: rgba(158,156,163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #000;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var( --text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var( --text-color);
      }
    }

  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var( --text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;

      }
    }

  }

}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var( --line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var( --text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";

  .m_icon {
    transform: rotate(90deg);
    color: var( --text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var( --text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";
  background: var( --line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;
  min-height: 361px;
  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var( --box-bg-color);
  position: relative;
  padding: 28px 20px;
 padding-bottom: 100px;

  .lon_list {
    padding: 19px 15px;
    width: 100%;
   
    opacity: 1;
    border-radius: 16px;

    border: 1.5px solid rgba(201, 223, 255, 1);

    .lon_title {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      .title_img {
        margin-right: 5px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(240, 245, 254, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
      }

      .line_right_button {
        width: 67px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(173, 184, 204, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var( --text-color);
      }
    }
    .lon_line2{
      width: 100%;
      height: 109px;
opacity: 1;
border-radius: 16px;
background: rgba(240, 245, 254, 1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 18px;
.line2_title{
  font-size: 18px;
font-weight: 900;
letter-spacing: 0px;
line-height: 18px;
color: rgba(0, 0, 0, 1);
}
.line2_button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129px;
height: 41px;
opacity: 1;
border-radius: 8px;
background: rgba(173, 184, 204, 1);
font-size: 16px;
font-weight: 700;
letter-spacing: 0px;
line-height: 24px;
color: var( --text-color);
}
    }
    .lon_line3{
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line3_left{
        font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18px;
color: rgba(153, 153, 153, 1);
      }
      .line3_right{
        font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18px;
color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}</style>